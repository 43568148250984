import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import makeSlug from 'lodash.kebabcase';
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { Layout } from '../../../components';

const BlogCategoriesPage = ({ data }) => (
  <Layout>
    <div />
    <Container>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={data.site.config.blogBasePath}>Blog</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          Categories
        </BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col md="8">
          <ul>
            {data.categories.group.map(({ fieldValue }) => (
              <li key={fieldValue}>
                <Link to={`${data.site.config.blogBasePath}/categories/${makeSlug(fieldValue)}`}>
                  {fieldValue}
                </Link>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  </Layout>
);

BlogCategoriesPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
  }).isRequired,
};

export default BlogCategoriesPage;

export const query = graphql`
  query BlogCategoriesPageQuery {
    site {
      ...ConfigBlogBasePath
    }
    categories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          regex: "/\\/content\\/blog-posts\\//"
        }
      }
    ) {
      group(field: frontmatter___categories) {
        fieldValue
      }
    }
  }
`;
